@import '../../assets/styles/main';
@import './../../assets/styles/colors.scss';

.react-select {
  &__control {
    border: none !important;
    box-shadow: none!important;
    cursor: pointer !important;
  }

  &__indicator, &__single-value, &__input-container {
    overflow: initial !important;
  }

  &__option:hover {}

  &__menu-list {
    padding: 0 !important;
  }
}

.react-select-blue {
  .react-select {
    &__control {
      border: none !important;
      box-shadow: none!important;
      background: $blue-orb-explorer !important;
    }

    &__indicator, &__single-value, &__input-container {
      color: $white !important;
    }
  }
}
