@import '../../assets/styles/main';
@import './../../assets/styles/colors.scss';

.react-multiselect {
    &__control {
        border: none !important;
        box-shadow: none!important;
        background: $white !important;
    }

    &__indicator, &__single-value, &__input-container {
        overflow: initial;
    }
    &__indicator {
        cursor: pointer !important;
    }

    &__multi-value {
        background: $blue-orb-explorer !important;
        border-radius: 6px !important;
        color: $white !important;
        &__label {
            color: $white !important;
        }
    }

    &__option:hover {}

    &__menu-list {
        padding: 0 !important;
    }
}

.multiselect-container {
    .optionListContainer {
        z-index: 10;
    }

    .search-wrapper {
        background: white !important;
    }
    .chip {
        background: $blue-orb-explorer !important;
    }
}
