@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato/lato-regular.eot");
  src: url("../fonts/lato/lato-regular.eot?#iefix") format('embedded-opentype'),
  url("../fonts/lato/lato-regular.woff2") format('woff2'),
  url("../fonts/lato/lato-regular.woff") format('woff'),
  url("../fonts/lato/lato-regular.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat/montserrat-regular.eot");
  src: url("../fonts/montserrat/montserrat-regular.eot?#iefix") format('embedded-opentype'),
  url("../fonts/montserrat/montserrat-regular.woff2") format('woff2'),
  url("../fonts/montserrat/montserrat-regular.woff") format('woff'),
  url("../fonts/montserrat/montserrat-regular.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
}
