div#wallet-collection {

  .small-gallery-img {
    max-height: 8rem;
    max-width: 10rem;
  }

  .card-img-actions {
    width: 100%;
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    .lg-react-element {
      height: 100%;
      img {
        height: 100%;
        object-fit: contain;
      }
    }
  }

  div.card-footer {

    > div > div {
      flex-direction: column;

      > div:last-child {
        align-items: center;
        display: flex;

        > span {
          margin-left: 5px;
          margin-right: 0;
        }

      }

    }

  }

}
