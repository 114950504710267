@import './../../assets/styles/main.scss';

.wallet-dropdown-menu {
  > div.dropdown-menu {
    @include media-breakpoint-up(md) {
      min-width: 340px !important;
    }

    > a.dropdown-item:hover {
      background-color: transparent;
      color: $drop-down-item;

      & div {
        color: $drop-down-item;
      }
    }
  }
}
