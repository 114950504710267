/* BANNER */
.banner {
  background-image: url('../../assets/images/orbexbg.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 400px;
  margin-bottom: 40px;
  padding: 40px;
  display: flex;
  align-items: center;

  .ban-content {
    padding-left: 40px;

    img {
      max-width: 400px;
      width: 100%;
    }

    h3 {
      padding-top: 20px;
    }

    p {
      font-size: 18px;
    }
  }
}

/* CONTENT GENERAL */
.mn-blocks {
  padding: 10px 0 40px 0;

  h4 {
    padding: 10px 0 10px 0;
    font-size: 26px;
  }

  .cards {
    border: none;
    box-shadow: 0 30px 80px #00000011;
    border-radius: 5px;
  }
}

/* Medium screens */
@media all and (max-width: 768px) {
  .home {
    padding: 0 20px;

    .banner {
      height: 200px;
      width: 100%;
      background-size: cover;
      padding: 0;

      .ban-content {
        padding-left: 0;

        img {
          width: 100%;
          max-width: 250px;
        }

        h4 {
          font-size: 20px;
        }

        p {
          font-size: 16px;
        }
      }
    }
  }
}
