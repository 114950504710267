$badge-bg: #dbeafe;
$black: black;
$blue-hover: #59b8ff;
$blue-orb-explorer: #32a0fc;
$blue-orb-explorer-dark: #0486f7;
$border-color: #ced4da;
$drop-down-item: #212529;
$footer-color: #f1f1f1;
$gray: #757575;
$gradient-blue-1: #33a1fd;
$gradient-blue-2: #3488c8;
$gradient-gray-1: #90cdff;
$gradient-gray-2: #7aa9cc;
$half-gray: #75757580;
$header-gray: #828282;
$hover-background: #f5f5f5;
$light-gray: #cdcdcd;
$main-color: #4e4e4e;
$white: #fff;
$white-bg: #fafafa;
$yellow-links: #ff9800;
