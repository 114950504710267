@import '../../assets/styles/main.scss';
@import './../../assets/styles/colors.scss';

.wallet-dropdown-button {
  border: 2px solid transparent;
  width: fit-content;
  border-radius: 7px;
  padding: 10px;
  background: transparent linear-gradient(78deg, $gradient-blue-1 0%, $gradient-blue-2 100%) 0 0 no-repeat padding-box;

  &:active {
    border: 2px solid $main-color;
  }

  &:hover {
    border-color: $header-gray;
  }

  div {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    > div {
      padding: 0 5px;
    }
  }
}

.wallet-element {
  div {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    padding: 0 5px;
  }
}
