@import './../../assets/styles/main.scss';
@import './../../assets/styles/colors.scss';

.copy-button,
.copy-button:active {
  background-color: transparent !important;
  background-image: none !important;
  background-size: cover;
  background-position: center !important;
  padding: 0 !important;
  width: auto;
  height: 20px;
  cursor: pointer !important;

  &.dark-theme {
    color: $header-gray !important;
  }

  &.light-theme {
    color: $white !important;
  }

  :hover {
    color: $black !important;
  }

  i {
    vertical-align: text-top !important;
  }
}
