div#withdraw-modal-5 {

  div.modal-content {

    div.modal-body {

      > div#orb-image {
        margin: auto;
        max-width: 200px;
      }

      > p {
        margin: auto;
        max-width: 450px;
        text-align: center;
      }

      > h6 {
        text-align: center;
      }

      > div#buttons {
        align-items: normal;
        display: flex;
        flex-direction: column;
        margin: auto;
        max-width: 200px;
        padding: 10px 0;
      }

    }

  }

}
