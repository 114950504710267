@import '../../assets/styles/main';
@import './../../assets/styles/colors.scss';

.transactionDetails {
  position: relative;
  padding-top: 1rem;

  @include media-breakpoint-up(md) {
    padding: 3rem 1.5rem 0 1.5rem;
  }

  .action-icons {
    font-size: 1.2rem;
    text-align: right;
    margin-bottom: 1.2rem;
    cursor: pointer;

    &.md {
      text-align: center;
      margin-bottom: 0;
      margin-right: 0;
      margin-top: 1.2rem;
    }

    &.lg-up {
      position: absolute;
      top: 12px;
      right: 16px;
      text-align: left;
    }
  }

  h1 {
    position: relative;
    margin-top: 20px;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }

    & > a {
      font-weight: 700 !important;
      font-size: 32px !important;
      text-decoration: none !important;
      color: $blue-orb-explorer;
      margin-left: 12px;
    }
  }

  h5 {
    font-size: 16px;
    font-weight: 700 !important;
    color: $blue-orb-explorer;
    margin-left: 12px;
  }

  a {
    text-decoration: none;
    font-size: 14px;

    @include media-breakpoint-up(lg) {
      font-size: 16px;
    }
  }

  .lg-react-element {
    text-align: center;

    img {
      width: auto !important;
      max-width: 100%;
    }
  }

  .details-card {
    border-radius: 9px;
    border: 1px solid $border-color;
    margin-top: 20px;
    background-color: $white-bg;

    .row {
      padding: 4px 24px;
      min-height: 26px;
    }

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }

    h4 {
      padding: 24px 32px;
      font-size: 18px;
      font-weight: 700 !important;
      border-bottom: 1px solid $border-color;
      border-radius: 9px 9px 0 0;
      background: $white;
    }

    .fw-bold {
      font-weight: 400 !important;
      color: $gray;
    }

    .col-xl-9 {
      text-align: left;

      @include media-breakpoint-up(md) {
        text-align: right;

        & > .d-flex.align-items-center.justify-content-start {
          justify-content: flex-end !important;
        }
      }
    }

    .blockchain-block {
      margin-left: 24px;
      font-weight: 400;
      color: $gray;
      font-size: 14px;

      a {
        font-size: inherit;
      }
    }

    hr {
      border-color: $border-color;
    }
  }

  .xl {
    display: none;
    @include media-breakpoint-up(xl) {
      display: block;
    }
  }

  .lg {
    display: none;
    @include media-breakpoint-only(lg) {
      display: block;
    }
  }

  .lg-up {
    display: none;
    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  .md {
    display: none;
    @include media-breakpoint-only(md) {
      display: block;
    }
  }

  .md-up {
    display: none;
    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  .sm {
    display: none;
    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  .sm-down {
    display: block;
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
}
