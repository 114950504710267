@import '../../assets/styles/main';

/* COLLECTIONS */
.mn-block-title {
  h4 {
    display: inline-block;
    width: 60%;
  }
  span {
    display: inline-block;
    width: 40%;
    text-align: right;
    font-size: 18px;
    a{
      color: $blue-orb-explorer;
      text-decoration: none;
      font-weight: 400 !important;
    }
  }
}
