@import '../../assets/styles/main';
@import './../../assets/styles/colors.scss';

/* FOOTER */
footer {
  background-color: $footer-color;
  margin-top: 60px;

  h3 {
    color: $blue-orb-explorer;
    font-size: 22px !important;
    margin-bottom: 10px;
  }

  form {
    display: flex;
    margin-bottom: 20px;
    column-gap: 15px;
  }

  input {
    padding: 10px;
    border-radius: 5px;
    border: none;
  }

  input[type='search']:focus {
    border-color: $blue-orb-explorer;
    outline: none;
  }

  input[type='email'] {
    flex: 2 auto;
    font-weight: 300;
    font-family: $main-font;
    &::placeholder {
      color: $gray;
      font-size: 15px;
    }
  }

  input[type='submit'] {
    flex: 1 auto;
    font-size: 18px;
  }

  .container {
    padding-top: 40px;
    padding-bottom: 40px;
    margin: auto;
  }

  h3 {
    color: $blue-orb-explorer;
    font-size: 22px;
    margin-bottom: 10px;
  }

  p {
    margin-top: 0;
  }
  a {
    text-decoration: none;
    color: $main-color !important;
  }

  a:hover {
    color: inherit !important;
  }

  a.social {
    color: $blue-orb-explorer !important;
    margin: 0 5px;
    transition: 0.2s ease-in-out;
  }

  a.social:hover {
    color: $blue-hover !important;
  }

  hr {
    color: $light-gray;
    border-top: 0.5px solid;
    border-bottom: 0;
    width: 97%;
    margin-top: 20px;
  }

  img {
    width: 100%;
    max-width: 260px;
    padding-bottom: 20px;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  ul li {
    margin-bottom: 5px;
  }

  .btn-grad {
    background-image: linear-gradient(to right, $gradient-blue-1 0%, $gradient-blue-2 51%, $gradient-blue-1 100%);
  }

  .btn-grad {
    transition: 0.5s;
    background-size: 200% auto;
    color: $white;
    display: block;
  }

  .btn-grad:hover {
    background-position: right center; /* change the direction of the change here */
    text-decoration: none;
  }

  .btn-grad:disabled {
    background-image: linear-gradient(to right, $gradient-gray-1 0%, $gradient-gray-2 51%, $gradient-gray-1 100%);
  }

  .sm-label{
    font-size: .9rem;
  }
  #select-env p{
    margin-bottom: 0;
  }

  #select-env a{
    padding-right: 5px;
  }
}

a.social {
  color: $blue-orb-explorer;
  margin: 0 5px;
}

a.social:hover {
  color: $blue-hover;
}

.ft-legal {
  text-align: right;
}

@media all and (max-width: 768px) {
  footer {
    h3 {
      margin-top: 30px;
    }
    form {
      flex-direction: column;
    }
    input[type='email'] {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
}
