@import '../../assets/styles/main.scss';

/* SPOTLIGHT */
.clickable-card {
    height: 100%;
    text-decoration: none;
    color: $main-color;
    &:hover {
        color: $main-color !important;
    }

    .img-container {
        height: 150px;
        img {
            object-fit: contain;
            height: 100%;
        }
    }

    hr {
        background: linear-gradient(265deg, #3488C8 0%, #33A1FD 100%);
        border-radius: 2px;
        opacity: 1;
        height: 3px !important;
        width: 40px;
    }

    .button {
        text-align: center;
    }
}

