@import './../../assets/styles/colors.scss';
@import '../../assets/styles/main.scss';

div.transaction-list-item {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  height: 200px;
  cursor: pointer;
  border: 1px solid $light-gray !important;

  @include media-breakpoint-only(md) {
    height: 250px;
  }

  @include media-breakpoint-only(sm) {
    height: 300px;
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    height: auto;
  }

  > :first-child {
    aspect-ratio: 0.7;
    padding: 5px;
  }

  // text part
  > div:last-child {
    flex-grow: 1;

    // all - common settings
    > div:nth-child(3),
    > div:nth-child(5) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    // first row
    > div:first-child {
      height: 25%;
      align-items: center;
      text-align: center;

      @include media-breakpoint-only(md) {
        height: 30%;
      }

      @include media-breakpoint-down(md) {
        height: auto;
      }

      // blockchain
      > div:first-child {
        display: flex;
        gap: 6px;
        justify-content: center;
        align-items: center;

        .blockchain-image {
          width: 20px;
          height: 20px;
        }
      }

      // collection
      > div:nth-child(3) {
        font-weight: 600;
      }

      // contract
      > div:nth-child(4) {
        font-family: monospace;
      }
    }

    // second row (the middle part)
    > div:nth-child(3) {
      height: 50%;
      display: flex;
      gap: 10px;
      justify-content: center;

      & span.badge {
        font-size: 14px;
        color: $main-color;
        font-weight: 300;
        font-family: monospace;
        background-color: #dbeafe !important;
      }

      & div:nth-child(2) {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-weight: 800;
      }

      > div:first-child,
      > div:nth-child(3) {
        display: flex;
        column-gap: 6px;
        align-items: center;
      }

      @include media-breakpoint-down(md) {
        height: 130px;

        > div:first-child,
        > div:nth-child(3) {
          display: flex;
          flex-direction: column;
          text-align: center;
        }

        > div:nth-child(2) {
          row-gap: 4px;
          align-content: center;
          flex-direction: row;
        }
      }

      @include media-breakpoint-down(sm) {
        padding: 12px 0;
        justify-content: space-evenly;
        gap: 2px;
      }
    }

    // third row
    > div:nth-child(5) {
      height: 25%;
      font-family: monospace;

      @include media-breakpoint-down(lg) {
        height: 18%;
        padding: 8px 0;
      }

      @include media-breakpoint-down(sm) {
        height: auto;
      }
    }
  }
}
