@import '../../assets/styles/main';
@import './../../assets/styles/colors.scss';

.transactions-table {
  .search-input {
    position: relative;
    button {
      position: absolute;
      height: 100%;
      right: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  > table {
    width: 100%;

    > tbody {
      border-top: none !important;

      .default-row {
        box-shadow: 0 30px 80px #00000011;
        td:first-child {
          border-top-left-radius: 8px;
        }
        td:last-child {
          border-top-right-radius: 8px;
        }
      }

      .expanded-row {
        border-radius: 0;
        box-shadow: none;
        td:first-child {
          border-bottom-left-radius: 8px;
        }
        td:last-child {
          border-bottom-right-radius: 8px;
        }
      }

      tr {
        td {
          border-width: 0;
          transition: 0.1s ease-in-out;
          color: $main-color;
          font-size: 16px;
          text-align: center;
          padding: 0 10px;

          @include media-breakpoint-down(sm) {
            padding: 0;
          }

          .expand-title {
            width: 1%;
            margin-right: 5px;
            white-space: nowrap;
          }

          .expand-value {
            padding-left: 0.5rem;
            overflow-wrap: anywhere;
          }

          .expand-title,
          .expand-value {
            text-align: left;
          }

          @include media-breakpoint-down(sm) {
            font-size: 13px;
          }

          div.cell-image {
            margin: auto;
            text-align: center;
          }

          div.cell-blockchain {
            text-align: center;
            img {
              width: 50px;
            }
          }

          a {
            color: $blue-orb-explorer;
            text-decoration: none;
          }
        }

        td.source-table .col,
        td.destination-table .col {
          position: relative;
        }

        td.source-table .col .row:nth-child(2),
        td.destination-table .col .row:nth-child(2) {
          position: absolute;
          left: 50%;
          transform: translateX(calc(-50% + 12px));
        }

        .source-expand,
        .destination-expand {
          vertical-align: baseline;
        }
      }
    }
    tr {
      background: $white;

      &.spacer {
        height: 20px;
        box-shadow: none;
      }
    }

    .image-container {
      height: 150px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  thead {
    box-shadow: $shadow;
    > tr {
      border-radius: 8px;
      th:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      th:nth-last-child(2) {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
    th {
      text-align: center;
      font-size: 16px;
      padding: 10px;
      border-bottom: none;

      @include media-breakpoint-down(md) {
        font-size: 14px;
      }
    }
  }

  .page-counter {
    @include media-breakpoint-down(md) {
      margin-bottom: 2rem;
      text-align: center;
    }
  }

  .pagination-selector {
    display: flex;
    justify-content: flex-end;
    list-style-type: none;
    color: $blue-orb-explorer;
    padding: 0;
    margin: 0;

    @include media-breakpoint-down(md) {
      justify-content: center;
    }

    .previous,
    .next {
      &.disabled {
        a {
          opacity: 0.5;
        }
      }
      & a {
        background: transparent !important;
      }
    }

    li {
      &,
      a {
        text-decoration: none;
      }

      a {
        transition: 0.15s ease-in-out;
        padding: 6px 12px;
        border: none;
        color: $blue-orb-explorer;
      }

      &:nth-child(2) {
        a {
          border-top-left-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem;
        }
      }

      &:nth-last-child(2) {
        a {
          border-top-right-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
        }
      }

      span {
        border: none;
      }

      &.selected {
        a {
          border-color: $blue-orb-explorer;
          background-color: $blue-orb-explorer;
          color: $white !important;
        }
      }

      &:not(.selected):hover a {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }

  .size-per-page {
    background-color: $blue-orb-explorer;
    border-color: $blue-orb-explorer;
    padding: 7px 4px;
  }

  .sort-arrows {
    font-size: 1.1rem;
  }

  .desc,
  .asc {
    opacity: 0.4;
  }

  .sorted-desc {
    .asc {
      opacity: 1;
    }
  }

  .sorted-asc {
    .desc {
      opacity: 1;
    }
  }

  .contract-table,
  .source-table,
  .destination-table {
    display: table-cell;
    white-space: nowrap;
    @include media-breakpoint-down(xl) {
      display: none;
    }
  }

  .quantity-table {
    display: table-cell;
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  .collection-table {
    display: table-cell;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .image-table {
    padding: 0 !important;
  }

  .explorer-table {
    display: none;
  }

  .time-table {
    white-space: break-spaces;
  }
}
