div#deposit-modal-1 {

  div.modal-content {

    div.modal-body {
      text-align: center;

      > div#orb-image {
        margin: auto;
        max-width: 200px;
      }

      > h5, p {
        text-align: center;
      }

      > div#buttons {
        align-items: normal;
        display: flex;
        flex-direction: column;
        margin: auto;
        max-width: 200px;

        > button {
          margin-bottom: 5px;
        }

      }

    }

  }

}
