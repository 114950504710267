@import './../../assets/styles/colors.scss';

.card-image {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: auto;
  height: auto;
  display: flex;
  align-items: stretch;

  .fadeIn {
    animation: fade 1s;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
    background-color: $light-gray;
  }
  100% {
    opacity: 1;
    background-color: transparent;
  }
}
