@import '../../assets/styles/main';
@import './../../assets/styles/colors.scss';

div#page-collection {
  margin-top: 50px;

  div#collection-description {
    margin: auto;
    text-align: center;
    max-width: 600px;
    padding: 20px 0 40px 0;

    img#collection-image {
      margin: 10px 0 20px 0;
      border-radius: 10px;
      max-width: 100%;
    }

    > p {
      color: $main-color;
    }
  }

  div#collections-tabs-tabpane-collections {
    div.card {
      div.card-image {
        display: flex;
        justify-content: center;

        img {
          width: 100%;
        }
      }

      div.card-body {
        div.collection-description {
          color: $main-color;
          font-family: 'Montserrat', sans-serif;
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }

  .infinite-scroll-component {
    overflow: unset !important;
  }

  /* FILTERS */
  .collection_filters {
    display: inline-flex;
    margin: 10px 0 40px 0;
    align-items: center;

    input {
      border-radius: 5px;
      font-weight: 300;
      font-family: $main-font;
      border: 1px solid rgba(117, 117, 117, 0.5);
      width: 100%;
      padding-left: 35px;
      background: transparent
        url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
        no-repeat 13px center;
      filter: invert(47%) sepia(4%) saturate(153%) hue-rotate(17deg) brightness(98%) contrast(97%);
    }
    input:focus {
      border-color: $blue-orb-explorer;
      outline: none;
    }
    input::placeholder {
      color: $gray;
      font-size: 15px;
    }

    Select {
      margin: 0 10px;
    }
  }
}
