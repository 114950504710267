@import '../../assets/styles/main';
@import './../../assets/styles/colors.scss';

.orbs-card-component {
  img {
    height: 250px;
    object-fit: contain;
  }

  h5 {
    font-size: 18px;
    font-weight: 400 !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  h6 {
    font-family: $main-font;
    font-size: 14px;
    color: $header-gray;
    font-weight: 300;
  }

  span {
    font-family: $main-font;
    font-weight: 300;
    font-size: 15px;
  }

  hr {
    background: linear-gradient(265deg, $gradient-blue-2 0%, $gradient-blue-1 100%);
    border-radius: 2px;
    opacity: 1;
    height: 3px !important;
    width: 40px;
    margin-top: 0;
  }
}
