div#withdraw-modal-3 {
  div.modal-content {
    div.modal-body {
      > div#orb-image {
        margin: auto;
        max-width: 200px;
      }

      > p {
        margin: auto;
        max-width: 450px;
        text-align: center;
      }

      > h5 {
        text-align: center;
        word-break: break-all;
      }

      > input {
        margin: 10px auto 0;
        max-width: 450px;
      }

      > div#buttons {
        align-items: normal;
        display: flex;
        flex-direction: column;
        margin: auto;
        max-width: 200px;
        padding: 10px 0;
      }
    }
  }
}
