@import '../../assets/styles/main';
@import './../../assets/styles/colors.scss';

header {
  max-width: 1200px;

  form {
    width: 50%;
  }

  @include media-breakpoint-only(md) {
    form {
      padding-right: 16px;
    }
  }

  @include media-breakpoint-down(lg) {
    form {
      margin: 10px 0;
      width: 100%;
    }
  }
}

header img {
  max-width: 150px;
}

.navToggle {
  font-size: 26px;
  color: $gradient-blue-1;
}
