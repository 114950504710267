@import 'bootstrap/scss/bootstrap.scss';
@import './fonts.scss';
@import './colors.scss';

$layout-boxed-width: 1200px;
$title-font: 'Open Sans', sans-serif;
$main-font: 'Monserrat', sans-serif;
$shadow: 0 20px 80px #00000011;

body {
  color: $main-color;
  font-weight: 400;

  .container {
    max-width: 1200px;
  }

  #root {
    margin: auto;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: $title-font;
      font-weight: 300;
    }
    p {
      font-family: $main-font;
      font-weight: 300;
    }
    a {
      font-family: $main-font;
      font-weight: 300;
      transition: 0.4s ease-in-out;
    }
    a:hover {
      color: $blue-orb-explorer-dark;
    }

    h1 {
      font-size: 30px;
    }

    h3 {
      font-size: 25px;
    }

    p {
      font-family: $main-font;
      font-weight: 300;
    }

    .form-control:focus {
      box-shadow: none;
    }

    div.card {
      border: 0 solid;
      box-shadow: 0 30px 80px #00000011;
      border-radius: 8px;
    }

    a {
      &:focus {
        box-shadow: none;
      }
    }

    .button {
      background: transparent linear-gradient(78deg, #33a1fd 0%, #3488c8 100%) 0 0 no-repeat padding-box;
      border-radius: 5px;
      color: $white;
      display: block;
      margin: auto;
      max-width: 100%;
      padding: 8px 0;
      text-decoration: none;
      width: 200px;
    }

    div.title-line {
      margin-bottom: 10px;
      padding: 0 0 1px 0;
      position: relative;

      &:before {
        background: transparent linear-gradient(265deg, #3488c8 0%, #33a1fd 100%) 0 0 no-repeat padding-box;
        border-radius: 2px;
        bottom: 0;
        content: '';
        height: 4px;
        left: 0;
        position: absolute;
        right: 0;
        width: 32px;
      }

      h3 {
        font-size: 17px;
        text-align: left;
      }
    }
  }

  .inline-img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 18px;
    height: 18px;

    display: inline-block;
  }

  .blockchain-img {
    @extend .inline-img;
    margin-right: 10px;

    &-ethereum {
      @extend .blockchain-img;
      background-image: url('./../../assets/images/blockchains/ethereum.png');
    }

    &-counterparty {
      @extend .blockchain-img;
      background-image: url('./../../assets/images/blockchains/counterparty.png');
    }

    &-firstOasisKlaytn {
      @extend .blockchain-img;
      background-image: url('./../../assets/images/blockchains/firstOasisKlaytn.png');
    }

    &-klaytn {
      @extend .blockchain-img;
      background-image: url('./../../assets/images/blockchains/klaytn.png');
    }

    &-matic {
      @extend .blockchain-img;
      background-image: url('./../../assets/images/blockchains/matic.png');
    }
  }

  button.btn-primary,
  a.btn-primary {
    background: transparent linear-gradient(78deg, #33a1fd 0%, #3488c8 100%) 0 0 no-repeat padding-box;
    border: 0 solid;
    border-radius: 5px;
    color: $white;
  }

  input.form-control {
    &:focus,
    &:active {
      border-color: $border-color;
      box-shadow: none;
    }
  }

  button.btn-primary,
  a.btn-primary,
  button.btn-light,
  button.btn-secondary {
    &:focus,
    &:active:focus {
      box-shadow: none;
    }
  }

  .modal-backdrop {
    min-width: 270px;
    height: 100%;
  }

  /* TABS */
  .tab-content {
    .nav-link.active {
      color: $blue-orb-explorer;
      font-weight: 500;
    }
    .nav-item.show,
    .nav-tabs,
    .nav-link {
      color: $main-color;
      border: $blue-orb-explorer;
    }

    .nav-tabs {
      gap: 30px;
    }

    .nav-link {
      padding: 0 !important;
    }
    .nav-link:hover {
      color: $blue-orb-explorer;
    }
  }

  .Toastify {
    --toastify-toast-width: 500px;
  }

  /* FORMS */
  header form {
    display: flex;
    max-width: 800px;
  }
  footer form {
    display: flex;
    margin-bottom: 20px;
  }
  header input,
  footer input {
    padding: 10px;
    border-radius: 5px;
  }
  footer input {
    border: none;
  }
  header input[type='search'],
  footer input[type='email'] {
    flex: 2 auto;
    font-weight: 300;
    font-family: $main-font;
  }
  header input[type='search'] {
    border: 1px solid rgba(117, 117, 117, 0.5);
    width: 100%;
    padding-left: 35px;
    background: transparent
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
      no-repeat 13px center;
    filter: invert(47%) sepia(4%) saturate(153%) hue-rotate(17deg) brightness(98%) contrast(97%);
  }
  input[type='search']:focus {
    border-color: $blue-orb-explorer;
    outline: none;
  }
  header input[type='search']::placeholder,
  footer input[type='email']::placeholder {
    color: $gray;
    font-size: 15px;
  }
  footer input[type='submit'] {
    flex: 1 auto;
    font-size: 18px;
  }
  .btn-grad {
    background-image: linear-gradient(to right, #33a1fd 0%, #3488c8 51%, #33a1fd 100%);
  }
  .btn-grad {
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    display: block;
  }
  .btn-grad:hover {
    background-position: right center; /* change the direction of the change here */
    text-decoration: none;
  }

  @media all and (max-width: 768px) {
    .container {
      padding: 0 20px;
    }
  }
}

.nowrap {
  white-space: nowrap;
}

.blue-link {
  color: $blue-orb-explorer;
  text-decoration: none;
  font-weight: 400 !important;
  background: none;
  border: none;
}

.form-select,
.form-control,
.react-multiselect__control,
.react-select__control {
  border: 1px solid #0000000d !important;
  box-shadow: 0 30px 80px #00000011 !important;
}

.form-select {
  padding: 8px 35px 8px 10px;
}

i.blockchain-image {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  height: 20px;
  width: 20px;

  @include media-breakpoint-up(sm) {
    height: 30px;
    width: 30px;
  }

  &#counterparty {
    background-image: url('./../../assets/images/blockchains/counterparty.png');
  }

  &#ethereum {
    background-image: url('./../../assets/images/blockchains/ethereum.png');
  }

  &#firstOasisKlaytn {
    background-image: url('./../../assets/images/blockchains/firstOasisKlaytn.png');
  }

  &#klaytn {
    background-image: url('./../../assets/images/blockchains/klaytn.png');
  }
}

.ver-separator::after {
  content: "|";
  padding: 0 5px;
}
